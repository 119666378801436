import React from 'react';
import { paramCase } from 'change-case';
import Layout from '../common/layout';
import SEO from '../common/seo';
import { PageProps } from '../../../server/templates/category';
import { useTranslation } from 'react-i18next';

// import Banner from '../../images/wsbfcm2023.png';

const Category = ({ categories, category: { key }, shops }: PageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title={t(`category-${paramCase(key)}`)} />

      {/*<div className="black_banner">*/}
      {/*  <a href={'https://campaign.worldshopping.global/BFCM2023?utm_source=worldshopping&utm_medium=ws&utm_campaign=wsg'}>*/}
      {/*    <img src={Banner} alt="" />*/}
      {/*  </a>*/}
      {/*</div>*/}

      <div className="container category__pickup">
        <h2 i18next-key="category-shoppickup-title">{t('category-shoppickup-title')}</h2>
        <div id="category__elfsight" className="elfsight-app-3d6ea74e-9691-4254-bc94-22ad5062c90b"></div>
        <ul className={'category__list'}>
          <li key="all" className={`category-all ${key === 'ALL' ? 'category__list--active' : ''}`}>
            <a href={`/categories/`} i18next-key={`category-all`}>
              {t(`category-all`)}
            </a>
          </li>
          {categories.map(category => (
            <li key={category.key} className={`category-${paramCase(category.key)} ${key === category.key ? 'category__list--active' : ''}`}>
              <a href={`/categories/${paramCase(category.key)}`} i18next-key={`category-${paramCase(category.key)}`}>
                {t(`category-${paramCase(category.key)}`)}
              </a>
            </li>
          ))}
        </ul>

        <ul className={'category__list-filtering'}>
          {shops.map(shop => (
            <li key={shop.url}>
              <a href={shop.url}>{shop.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default Category;
