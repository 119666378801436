import GatsbyTemplate from './gatsby-template';

import CategoryComponent from '../../frontend/components/category';

import { Shop, Category } from '../infrastructure/shop-service';
import { ResourceBundles } from '../infrastructure/language-service';

export type PageProps = {
  categories: Category[];
  category: Category;
  shops: Shop[];
  resourceBundles: ResourceBundles;
};

export default GatsbyTemplate<PageProps>(CategoryComponent);
